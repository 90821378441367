#stade {
    overflow-x: hidden;
}

#stade .stade-container {
    min-height: 80vh;
    background: url("../../public/images/stade_ext.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
}

#stade .stade-content {
    flex: 1;
    display: flex;
    height: 100%;
}

.stade-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Pacifico", serif;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    text-align: center;
    color: white;
}

.stade-title h1 {
    font-size: 5rem;
    margin-top: 7rem;
    margin-bottom: 7rem;
}


@media screen and (max-width: 768px) {
    #stade .stade-container {
        min-height: 100vh;
    }

    .stade-title h1 {
        margin-bottom: 2rem;
    }
}

.stade-title p {
    font-size: 2rem;
}

.stade-text {
    display: flex;
    align-items: center;
}

.stade-text-content, .stade-interior-content {
    position: relative;
    padding: 1rem 2rem;
    margin: 2rem;
    color: black;
    background-color: rgba(255, 255, 255, 1);
    font-weight: 500;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.stade-text-content .btn {
    display: flex;
    align-items: center;
    margin: 2rem 0 0 auto;
}

.stade-btn-icon {
    position: relative;
    flex: 0;
    margin-left: 0.5rem;
}

.stade-interior {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background: url("../../public/images/stade.png");
    background-size: cover;
    background-position: center;
}

.stade-interior .stade-interior-title {
    font-size: 5rem;
    font-family: "Pacifico", serif;
    color: white;
    margin: 2rem 0;
    text-align: center;
}

.stade-interior .stade-interior-text {
    flex: 1;
    align-items: stretch;
    padding: 2rem;
}

.stade-interior-content {
    transition: height 1s;
    margin: 0 0 4rem;
    min-height: 60vh;
}

.stade-interior-content .interior-content {
    display: flex;
    height: 100%;
    flex-direction: column;
}

.stade-interior-content .stadium-part-image {
    flex: 1;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
}

.stade-interior-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
}

.stade-interior-column .btn {
    min-width: 50%;
    margin: 4rem 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stade-interior-column .stadium-part-btn-icon {
    position: relative;
    margin-left: 1rem;
    flex: 0;
}

@media screen and (max-width: 768px) {
    .stade-text-content {
        margin: 2rem 0 0;
    }
}

@media screen and (max-width: 992px) {
    .stade-interior-column .btn {
        margin: 1rem 0;
        width: 100%;
    }

    .stade-interior-content {
        margin-bottom: 2rem;
    }
}
