#boutique {
    background-color: inherit;
}

#boutique::before {
    content: "";
    display: block;
    position: fixed;
    width: 300%;
    height: 100%;
    top: 50%;
    left: 50%;
    border-radius: 100%;
    transform: translateX(-50%) skewY(-8deg);
    background-color: var(--secondary-color);
    z-index: -1;
    animation: wave 8s ease-in-out infinite alternate;
}

@keyframes wave {
    0% {
        transform: translateX(-50%) skew(0deg, -8deg);
    }

    100% {
        transform: translateX(-30%) skew(8deg, -4deg);
    }
}

#boutique header {
    margin-top: 1rem;
}

.header-line {
    display: flex;
    min-height: 400px;
}

.header-line-arthur, .header-line-side {
    padding: 0 1rem 1rem;
}

.header-arthur-container, .header-side-container, .header-foot-container {
    cursor: pointer;
    background-color: lightgrey;
    padding: 1rem;
    width: 100%;
    height: 100%;
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
}

.header-arthur-container {
    background-image: url("../../public/images/pub_usm_arthur.png");
    align-items: flex-end;
}


.header-arthur-title {
    width: 100%;
    font-size: 3rem;
    font-family: "Pacifico", serif;
    text-align: center;
    color: white;
}

.header-side-container {
    background-image: url("../../public/images/beziers.jpg");
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header-side-title {
    font-size: 7rem;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    -webkit-text-stroke: 2px var(--primary-color);
}

.header-side-desc {
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    margin: 0;
    text-align: center;
}

.header-foot-container {
    height: 100px;
    background-color: var(--primary-color);
    color: white;
    display: flex;
    align-items: center;
}

.header-foot-container p {
    margin: 0;
    font-size: 2rem;
    font-family: "Pacifico", serif;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header-foot-container p::after {
    content: "";
    width: 10%;
    height: 3px;
    background-color: white;
    margin: 0.5rem;
}

.shop-title {
    text-align: center;
    font-size: 5rem;
    font-family: "Pacifico", serif;
}

.shop-title{
    margin: 3rem 0;
}

.shop-content {
    padding-bottom: 2rem;
}

.shop-list {
    list-style-type: none;
    padding: 0;
}

.shop-item {
    height: 500px;
    margin: 1.5rem 0;
}

.shop-item-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.shop-item-container:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.shop-item-title {
    font-size: 2rem;
    font-family: "Pacifico", serif;
    text-align: center;
    margin: 0;
}

.shop-item-desc {
    text-align: center;
}

.shop-item-img-container {
    width: 100%;
    flex: 1;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.shop-item-footer {
    margin: 2rem 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.shop-item-price {
    font-weight: bold;
    font-size: 1.25rem;
    display: flex;
}

.shop-item-price.solde .shop-real-price {
    text-decoration: line-through;
}

.shop-solde {
    margin-left: 1rem;
    color: red;
}

.shop-item-cart {
    border: 2px solid var(--primary-color);
    padding: 0.5rem 1rem;
    border-radius: 10px;
    color: var(--primary-color);
    display: flex;
    align-items: center;
}

.shop-item-icon {
    position: relative;
    width: auto;
    margin-left: 0.5rem;
}

@media screen and (max-width: 768px) {
    .header-arthur-title {
        font-size: 2rem;
    }

    .header-side-title {
        font-size: 4rem;
    }

    .header-side-desc {
        font-size: 1.5rem;
    }
}