body,
html {
    margin: 0;
    padding: 0;
    height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

svg.svg-home {
    display: block;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
}

.scrollElement {
    position: absolute;
    height: 6000px;
    width: 100px;
    top: 0;
    z-index: 0;
}

.text-svg-usm {
    font-size: 50px;
}

.text-home-usm {
    font-size: 7rem;
    position: fixed;
    color: white;
    top: 0;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.btn-home {
    margin: 1.5rem 3rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;

}

.btn-home .btn-icon {
    position: relative;
    width: auto;
    margin-left: 1rem;
}


@media screen and (max-width: 992px) {
  .text-svg-usm {
      font-size: 30px;
      transform: translateX(70px);
  }
  .text-home-usm {
      font-size: 4rem;
  }
}

@media screen and (max-width: 768px) {
  .text-svg-usm {
      font-size: 30px;
  }
  .text-home-usm {
      font-size: 2rem;
  }
}