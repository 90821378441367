:root {
    --primary-color: #012649;
    --secondary-color: #d2a533;
    --primary-fcb: #203C63;
    --secondary-fcb: #97202C;
    --primary-rco: #181818;
    --secondary-rco: #D0B27C;
}

#club, #stade, #objectifs, #boutique, #remerciements {
    width: 100%;
    background-color: var(--primary-color);
}

/* Style USM */
.btn.btn-primary-usm {
    border: 2px solid var(--primary-color) !important;
    background-color: var(--primary-color) !important;
    color: white !important;
    font-weight: bold;
}

.btn.btn-primary-usm:hover {
    border: 2px solid var(--primary-color) !important;
    background-color: white !important;
    color: var(--primary-color) !important;
    font-weight: bold;
}

.btn.btn-secondary-usm {
    border: 2px solid var(--secondary-color) !important;
    background-color: var(--secondary-color) !important;
    color: white !important;
    font-weight: bold;
}

.btn.btn-secondary-usm:hover {
    border: 2px solid var(--secondary-color) !important;
    background-color: white !important;
    color: var(--secondary-color) !important;
    font-weight: bold;
}


/* Style FCB */
.btn.btn-primary-fcb {
    border: 2px solid var(--primary-fcb) !important;
    background-color: var(--primary-fcb) !important;
    color: white !important;
    font-weight: bold;
}

.btn.btn-primary-fcb:hover {
    border: 2px solid var(--primary-fcb) !important;
    background-color: white !important;
    color: var(--primary-fcb) !important;
    font-weight: bold;
}


.btn.btn-secondary-fcb {
    border: 2px solid var(--secondary-fcb) !important;
    background-color: var(--secondary-fcb) !important;
    color: white !important;
    font-weight: bold;
}

.btn.btn-secondary-fcb:hover {
    border: 2px solid var(--secondary-fcb) !important;
    background-color: white !important;
    color: var(--secondary-fcb) !important;
    font-weight: bold;
}

/* Style RCO */
.btn.btn-primary-rco {
    border: 2px solid var(--primary-rco) !important;
    background-color: var(--primary-rco) !important;
    color: white !important;
    font-weight: bold;
}

.btn.btn-primary-rco:hover {
    border: 2px solid var(--primary-rco) !important;
    background-color: white !important;
    color: var(--primary-rco) !important;
    font-weight: bold;
}


.btn.btn-secondary-rco {
    border: 2px solid var(--secondary-rco) !important;
    background-color: var(--secondary-rco) !important;
    color: white !important;
    font-weight: bold;
}

.btn.btn-secondary-rco:hover {
    border: 2px solid var(--secondary-rco) !important;
    background-color: white !important;
    color: var(--secondary-rco) !important;
    font-weight: bold;
}