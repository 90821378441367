@import url("https://fonts.googleapis.com/css?family=Pacifico");

#club {
    overflow-x: hidden;
}

#club header {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

#club header::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../../public/images/accueil_bg.webp");
    background-size: cover;
    filter: brightness(50%);
}

#header-container {
    position: relative;
    width: 100%;
    font-family: "Libre Baskerville", serif;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.header-devise {
    margin-top: 1rem !important;
    text-align: center;
    font-size: 2em;
    color: #d2a533;
    font-family: "Pacifico", serif;
}

#header-logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

#header-logo img {
    max-width: 100%;
    width: 60%;
    opacity: 0;
    margin-left: 0;
}

#header-logo img.loaded {
    opacity: 1;
    transition: all 1s ease-out;
}

#header-text {
    color: #d2a533;
    font-size: 5rem;
    font-family: "Pacifico", serif;
    flex: 1;
    text-align: center;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#header-arrow {
    position: relative;
    color: #d2a533;
    cursor: pointer;
    margin-bottom: 5rem;
}

.section-duo {
    display: flex;
    align-items: stretch;
    min-height: 80vh;
    background-color: white;
}

.section-duo .section-image {
    background-size: cover;
    background-position: center 0;
}

.section-duo .section-text {
    align-self: flex-start;
    display: flex;
    flex-wrap: wrap;
    padding: 5%;
}

.section-duo .section-text p {
    font-size: 1.25em;
    text-align: justify;
}

#presentation-image {
    background-image: url("../../public/images/usm_presentation.png");
}

h2 {
    font-size: 3em;
    width: 100%;
    display: flex;
    flex-direction: column;
}

h2::after {
    content: "";
    top: 100%;
    width: 20%;
    background-color: #012649;
    width: 4rem;
    height: 1vh;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

#empty-background {
    position: relative;
    min-height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

#empty-background::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../../public/images/mont-saint-michel.webp") fixed;
    background-size: cover;
    background-position: center;
    filter: brightness(50%);
}

#empty-background #empty-bg-text {
    position: relative;
    color: white;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#empty-background #empty-bg-text p {
    margin-bottom: 1.5em;
    font-size: 5rem;
    font-family: "Pacifico", serif;
}

#empty-background .btn-transparent {
    color: white !important;
    border: 2px solid white !important;
    font-size: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

#empty-background .btn-transparent .empty-bg-fa {
    flex: 0;
    position: relative;
    margin-left: 1rem;
}

#devise-image {
    background-image: url("../../public/images/devise.webp");
}

#navigation {
    min-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-wrap: wrap;
    padding: 2rem;
}

#navigation a {
    text-decoration: none;
}

#navigation #navigation-title {
    width: 100%;
    position: relative;
    color: white;
    text-align: center;
    font-size: 70px;
    font-family: "Pacifico", serif;
    margin-bottom: 3rem;
}

#navigation::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../../public/images/compass.jpg");
    background-size: cover;
    filter: brightness(30%);
}

#navigation .navigation-card {
    max-height: 600px;
    min-height: 460px;
    height: 25vw;
    overflow: hidden;
    position: relative;
    transition: transform 250ms;
    padding-bottom: 2rem;
}

#navigation .navigation-card:hover {
    transform: translateY(-5px);
}

#navigation .navigation-card:hover .navigation-card-background {
    transform: scale(1.05);
}

#navigation .navigation-card:hover .navigation-card-content svg {
    transform: translate(-20%, -20%) scale(1.2);
}

#navigation-items {
    padding: 0 2rem;
    list-style-type: none;
}

#navigation-items .navigation-card-background {
    border-radius: 10px;
    height: 100%;
    left: 0px;
    position: absolute;
    top: 0px;
    transition: transform 250ms;
    width: 100%;
    z-index: 1;
}

#navigation-items .navigation-card-content {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.4));
    border-radius: 10px;
    height: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-size: cover;
    background-position: center;
    background-color: #1e1e1e;
    border-radius: 10px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
}

#navigation-items .navigation-card-content .navigation-card-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 30px;
    width: 100%;
    height: 100%;
}

#navigation-items .navigation-card-content .navigation-card-info span {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

#navigation-items .navigation-card-content .navigation-card-info .navigation-card-title {
    color: white;
    color: gray(245);
    font-size: 2em;
}

#navigation-items .navigation-card-content .navigation-card-info .navigation-card-desc {
    color: white;
    color: gray(200);
    font-size: 0.9em;
}

.navigation-card-icon {
    padding: 2rem;
    align-self: flex-end;
}

#navigation-items .navigation-card-content .fa-icon {
    color: white;
    font-size: 5em;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    transition: transform 250ms;
    position: relative;
}

@media screen and (max-width: 992px) {
    .section-duo {
        min-height: 0;
    }
}

@media screen and (max-width: 768px) {
    .header-devise {
        margin-top: 3rem !important;
    }

    #header-text {
        font-size: 3rem;
    }

    .section-duo .section-text p {
        font-size: 1rem;
    }
}