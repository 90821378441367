#thanks-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-x: hidden;
}

#thanks-bg {
    position: absolute;
    background: url("../../public/images/beziers.jpg");
    background-size: cover;
    background-position: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: brightness(75%);
}

.thanks-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
}

.thanks-columns {
    flex: 1;
    padding: 2rem;
}

@media screen and (max-width: 768px) {
    .thanks-columns {
        padding: 0;
    }
}

.thanks-column-card {
    height: 100%;
    background-color: white;
    padding: 2rem;
    display: flex;
    flex-direction: column;
}

.thanks-column-clubs {
    flex: 1;
}

.thanks-title {
    font-family: "Pacifico", serif;
    font-size: 5rem;
    color: white;
    text-align: center;
}

.thanks-club {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.thanks-club-text {
    text-align: center;
    font-family: "Pacifico", serif;
    font-size: 2rem;
}

.thanks-club-img {
    width: 50%;
}

.thanks-arthur-profile {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.thanks-arthur-img {
    flex: 1;
    background: url("../../public/images/arthur_profile.jpg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 30vh;
}

.thanks-arthur-links {
    display: flex;
    justify-content: space-evenly;
    margin-top: 2rem;
}

.thanks-arthur-links a {
    position: relative;
    margin: 0 1rem;
}

.thanks-arthur-links svg {
    position: relative;
    height: auto;
}

.thanks-arthur-links svg:hover {
    position: relative;
    height: auto;
    fill: var(--primary-color);
}

.fogwrapper {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    -webkit-filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
    filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
}
#foglayer_01,
#foglayer_02,
#foglayer_03 {
    height: 100%;
    position: absolute;
    width: 200%;
}
#foglayer_01 .image01,
#foglayer_01 .image02,
#foglayer_02 .image01,
#foglayer_02 .image02,
#foglayer_03 .image01,
#foglayer_03 .image02 {
    float: left;
    height: 100%;
    width: 50%;
}
#foglayer_01 {
    -webkit-animation: foglayer_01_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;
    -moz-animation: foglayer_01_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;
    animation: foglayer_01_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;
}
#foglayer_02,
#foglayer_03 {
    -webkit-animation: foglayer_02_opacity 21s linear infinite, foglayer_moveme 13s linear infinite;
    -moz-animation: foglayer_02_opacity 21s linear infinite, foglayer_moveme 13s linear infinite;
    animation: foglayer_02_opacity 21s linear infinite, foglayer_moveme 13s linear infinite;
}

#foglayer_01 .image01,
#foglayer_01 .image02 {
    background: url("https://raw.githubusercontent.com/danielstuart14/CSS_FOG_ANIMATION/master/fog1.png") center
        center/cover no-repeat transparent;
}
#foglayer_02 .image01,
#foglayer_02 .image02,
#foglayer_03 .image01,
#foglayer_03 .image02 {
    background: url("https://raw.githubusercontent.com/danielstuart14/CSS_FOG_ANIMATION/master/fog2.png") center
        center/cover no-repeat transparent;
}

/* ---------- Keyframe Layer 1 ---------- */
@-webkit-keyframes foglayer_01_opacity {
    0% {
        opacity: 0.1;
    }
    22% {
        opacity: 0.5;
    }
    40% {
        opacity: 0.28;
    }
    58% {
        opacity: 0.4;
    }
    80% {
        opacity: 0.16;
    }
    100% {
        opacity: 0.1;
    }
}
@-moz-keyframes foglayer_01_opacity {
    0% {
        opacity: 0.1;
    }
    22% {
        opacity: 0.5;
    }
    40% {
        opacity: 0.28;
    }
    58% {
        opacity: 0.4;
    }
    80% {
        opacity: 0.16;
    }
    100% {
        opacity: 0.1;
    }
}
@-o-keyframes foglayer_01_opacity {
    0% {
        opacity: 0.1;
    }
    22% {
        opacity: 0.5;
    }
    40% {
        opacity: 0.28;
    }
    58% {
        opacity: 0.4;
    }
    80% {
        opacity: 0.16;
    }
    100% {
        opacity: 0.1;
    }
}
@keyframes foglayer_01_opacity {
    0% {
        opacity: 0.1;
    }
    22% {
        opacity: 0.5;
    }
    40% {
        opacity: 0.28;
    }
    58% {
        opacity: 0.4;
    }
    80% {
        opacity: 0.16;
    }
    100% {
        opacity: 0.1;
    }
}
/* ---------- Keyframe Layer 2 ---------- */
@-webkit-keyframes foglayer_02_opacity {
    0% {
        opacity: 0.5;
    }
    25% {
        opacity: 0.2;
    }
    50% {
        opacity: 0.1;
    }
    80% {
        opacity: 0.3;
    }
    100% {
        opacity: 0.5;
    }
}
@-moz-keyframes foglayer_02_opacity {
    0% {
        opacity: 0.5;
    }
    25% {
        opacity: 0.2;
    }
    50% {
        opacity: 0.1;
    }
    80% {
        opacity: 0.3;
    }
    100% {
        opacity: 0.5;
    }
}
@-o-keyframes foglayer_02_opacity {
    0% {
        opacity: 0.5;
    }
    25% {
        opacity: 0.2;
    }
    50% {
        opacity: 0.1;
    }
    80% {
        opacity: 0.3;
    }
    100% {
        opacity: 0.5;
    }
}
@keyframes foglayer_02_opacity {
    0% {
        opacity: 0.5;
    }
    25% {
        opacity: 0.2;
    }
    50% {
        opacity: 0.1;
    }
    80% {
        opacity: 0.3;
    }
    100% {
        opacity: 0.5;
    }
}
/* ---------- Keyframe Layer 3 ---------- */
@-webkit-keyframes foglayer_03_opacity {
    0% {
        opacity: 0.8;
    }
    27% {
        opacity: 0.2;
    }
    52% {
        opacity: 0.6;
    }
    68% {
        opacity: 0.3;
    }
    100% {
        opacity: 0.8;
    }
}
@-moz-keyframes foglayer_03_opacity {
    0% {
        opacity: 0.8;
    }
    27% {
        opacity: 0.2;
    }
    52% {
        opacity: 0.6;
    }
    68% {
        opacity: 0.3;
    }
    100% {
        opacity: 0.8;
    }
}
@-o-keyframes foglayer_03_opacity {
    0% {
        opacity: 0.8;
    }
    27% {
        opacity: 0.2;
    }
    52% {
        opacity: 0.6;
    }
    68% {
        opacity: 0.3;
    }
    100% {
        opacity: 0.8;
    }
}
@keyframes foglayer_03_opacity {
    0% {
        opacity: 0.8;
    }
    27% {
        opacity: 0.2;
    }
    52% {
        opacity: 0.6;
    }
    68% {
        opacity: 0.3;
    }
    100% {
        opacity: 0.8;
    }
}
/* ---------- Keyframe moveMe ---------- */
@-webkit-keyframes foglayer_moveme {
    0% {
        left: 0;
    }
    100% {
        left: -100%;
    }
}
@-moz-keyframes foglayer_moveme {
    0% {
        left: 0;
    }
    100% {
        left: -100%;
    }
}
@-o-keyframes foglayer_moveme {
    0% {
        left: 0;
    }
    100% {
        left: -100%;
    }
}
@keyframes foglayer_moveme {
    0% {
        left: 0;
    }
    100% {
        left: -100%;
    }
}

@media only screen and (min-width: 280px) and (max-width: 767px) {
    #foglayer_01 .image01,
    #foglayer_01 .image02,
    #foglayer_02 .image01,
    #foglayer_02 .image02,
    #foglayer_03 .image01,
    #foglayer_03 .image02 {
        width: 100%;
    }
}
