footer {
    background-color: var(--primary-color);
    color: white;
}

.footer-infos {
    padding: 2rem 0;
    display: flex;
    justify-content: center;
}

.footer-infos-column {
    margin: 0 1rem;
}

footer .footer-title {
    font-size: 2rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
}

.footer-usm {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
}

.footer-usm-content {
    margin: 0 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


@media screen and (max-width: 768px) {
    .footer-usm-content {
        margin: 0;
    }
}

.footer-logo {
    width: 35%;
}

.footer-logo-title {
    font-family: "Pacifico", serif;
    margin-top: 1rem;
}

footer a {
    text-decoration: none;
    color: inherit;
}

footer a:hover {
    color: var(--secondary-color);
}