#roulette {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.roulette-container {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.roulette-content {
    flex: 1;
    padding-bottom: 2rem;
}

#roulette h1 {
    font-size: 5rem;
    font-family: "Pacifico", serif;
    margin: 2rem 1rem;
    text-align: center;
}

.markers {
    position: absolute;
    left: 50%;
    top: 10px;
    margin-left: -25px;
    width: 0;
    height: 0;
    border: 25px solid #000;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    z-index: 1000;
}


#btn-start {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -50px 0 0 -50px;
    width: 100px;
    height: 100px;
    font-weight: bold;
    background: #faf0e6;
    border-radius: 50px;
    z-index: 1000;
    cursor: pointer;
    border: 0;
    padding: 0;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#btn-start:disabled {
    cursor: not-allowed;
}

.roulette-column {
    margin: 0 1rem;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.roulette-column-title {
    background-color: var(--primary-color);
    padding: 1rem;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1rem;
}

.theme-fcb .roulette-column-title {
    background-color: var(--primary-fcb);
}

.theme-rco .roulette-column-title {
    background-color: var(--primary-rco);
}


.roulette-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
}

#wheel {
    flex: 1;
    position: relative;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
}


.roulettes-items {
    list-style-type: none;
    padding: 0;
}

.roulette-config-list {
    list-style-type: none;
    padding: 0 1rem;
}

.roulette-config-add, .roulette-wheels-add {
    font-weight: bold;
    margin-bottom: 1rem;
}

.roulette-config-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 0;
}

.roulette-config-element {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.roulette-config-element button {
    line-height: 0.75rem;
    padding: 0.5rem;
    margin: 0 0.5rem;
}

text.roulette-text {
    font-size: 20px;
}

@media screen and (max-width: 992px) {
    text.roulette-text {
        font-size: 10px;
    }

    #btn-start {
        width: 50px;
        height: 50px;
        margin: -25px 0 0 -25px;
        font-size: 0.5rem;
    }
}