#objectifs {
    background-color: white;
    overflow-x: hidden;
}

.goals h1 {
    font-size: 5rem;
    color: var(--primary-color);
    font-family: "Pacifico", serif;
    text-align: center;
    padding: 2rem; 
}

@media screen and (max-width: 768px) {
    .goals h1 {
        font-size: 4rem;
    }
}

.timeline {
	position: relative;
    padding: 3rem 0;
}

.timeline::before {
    content: '';
    background: #C5CAE9;
    width: 5px;
    height: 95%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.timeline-item {
	width: 100%;
	margin-bottom: 2rem;
}

.timeline-item:nth-child(even) .timeline-content {
    float: right;
    padding: 40px 30px 10px 30px;
}

.timeline-item:nth-child(even) .timeline-content .date {
    right: auto;
    left: 0;
}

.timeline-item:nth-child(even) .timeline-content::after {
    content: '';
    position: absolute;
    border-style: solid;
    width: 0;
    height: 0;
    top: 30px;
    left: -15px;
    border-width: 10px 15px 10px 0;
    border-color: transparent #f5f5f5 transparent transparent;
}

.timeline-item::after {
    content: '';
    display: block;
    clear: both;
}

.timeline-content {
	position: relative;
	width: 45%;
	padding: 10px 30px;
	border-radius: 4px;
	background: #f5f5f5;
	box-shadow: 0 20px 25px -15px rgba(0, 0, 0, .3);
}

.timeline-content::after {
    content: '';
    position: absolute;
    border-style: solid;
    width: 0;
    height: 0;
    top: 30px;
    right: -15px;
    border-width: 10px 0 10px 15px;
    border-color: transparent transparent transparent #f5f5f5;
}

.timeline-img {
	width: 30px;
	height: 30px;
	background: var(--primary-color);
	border-radius: 50%;
	position: absolute;
	left: 50%;
	margin-top: 25px;
	margin-left: -15px;
}

.timeline a {
	background: var(--primary-color);
	color: white;
	padding: 8px 20px;
	text-transform: uppercase;
	font-size: 14px;
	margin-bottom: 20px;
	margin-top: 10px;
	display: inline-block;
	border-radius: 2px;
	box-shadow: 0 1px 3px -1px rgba(0, 0, 0, .6);
}

.timeline a:hover, .timeline a:active, .timeline a:focus {
    background: darken(var(--primary-color), 10%);
    color: white;
    text-decoration: none;
}

.timeline-card {
	padding: 0!important;
}

.timeline-card p {
    padding: 0 1rem 1rem;
}

.timeline-card a {
    margin-left: 20px;
}

.timeline-item .timeline-img-header {
    background-position: center;
    background-repeat: no-repeat;
}

.timeline-img-header {

	height: 200px;
	position: relative;
	margin-bottom: 20px;
}

.timeline h2 {
    margin: 1.5rem 0 1rem;
}

.timeline h2::after {
    content: none;
}

.timeline-img-header h2 {
    color: white;
    position: absolute;
    bottom: 0;
    padding: 1rem;
    margin: 0;
    background-color: rgba(255, 255, 255, 0.25);
}

.timeline .date {
	background: #FF4081;
	display: inline-block;
	color: white;
	padding: 10px;
	position: absolute;
	top: 0;
	right: 0;
}

@media screen and (max-width: 768px) {
    .timeline::before {
        left: 50px;
    }

    .timeline .timeline-img {
        left: 50px;
    }

    .timeline .timeline-content {
        max-width: 100%;
        width: auto;
        margin-left: 70px;
    }

    .timeline .timeline-item:nth-child(even) .timeline-content {
        float: none;

    }

    .timeline .timeline-item:nth-child(odd) .timeline-content::after {
        content: '';
        position: absolute;
        border-style: solid;
        width: 0;
        height: 0;
        top: 30px;
        left: -15px;
        border-width: 10px 15px 10px 0;
        border-color: transparent #f5f5f5 transparent transparent;
    }
	
}
